.arrow-container {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 5px rgba(51, 61, 71, 0.4);
  z-index: 10;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  bottom: 35px;
  right: 35px;
  width: 45px;
  height: 45px;
}
.round {
  position: absolute;
  border: 2px solid #c74778;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 8px;
}

.arrow {
  position: absolute;
  font-size: 24px;
  font-weight: 900;
  color: #c74778;
  text-shadow: 2px 2px 5px rgba(51, 61, 71, 0.3);
}
.bottom-arrow {
  margin-top: 8px;
}

@keyframes floating-up {
  0% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
  25% {
    opacity: 0;
    transform: translateY(-10px) scale(0.9);
  }
  35% {
    opacity: 0;
    transform: translateY(10px) scale(0.9);
  }
  55% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}
.floating-up {
  animation-name: floating-up;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.arrow.bottom-arrow.floating-up {
  animation-name: floating-up;
  animation-duration: 1.4s;
  animation-delay: 0.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@media (pointer: fine) {
  .round:hover .arrow {
    animation-name: floating-up;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  .round:hover .arrow.bottom-arrow {
    animation-name: floating-up;
    animation-duration: 1.4s;
    animation-delay: 0.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .arrow-container:hover {
    background-color: rgba(255, 255, 255, 0.6);
  }
}
