.about-me {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* Take up the full screen */
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black */
    opacity: 0;
    /* Start transparent */
    z-index: 1000;
    /* Appear in front of other elements */
    transition: opacity 0.3s ease-in-out;
    /* Fade in/out smoothly */
    pointer-events: none;
    /* Ignore clicks when the page is closed */
    overflow: auto;
    /* Enable scrolling if the content is too long */
}

/* Base styles for the about-me-content that applies to all screen sizes */
.about-me-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  margin: auto;
  overflow: auto; /* Ensure content is scrollable if it overflows */
  transition: transform 0.3s ease-in-out; /* Smooth transformation */
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .about-me-content {
    width: 50%; /* Set width to 50% of the view width for desktops */
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .about-me-content {
    width: 60%; /* Set width to 60% of the view width for tablets */
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .about-me-content {
    width: 75%; /* Set width to 75% of the view width for landscape phones */
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575px) {
  .about-me-content {
    width: 100%; /* Set width to 100% of the view width for portrait phones */
  }
}


.about-me.open {
    opacity: 1;
    /* Fade in */
    pointer-events: auto;
    /* Enable clicks when the page is open */
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    /* White icon */
    cursor: pointer;
}

img {
    max-width: 100%; /* Default to full width */
    height: auto; /* Keep aspect ratio */
  }
  
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) { 
    img {
      max-width: 800px; /* Set max-width to 800px for large devices */
    }
  }
  
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    img {
      max-width: 500px; /* Set max-width to 500px for medium devices */
    }
  }
  
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    img {
      max-width: 300px; /* Set max-width to 300px for small devices */
    }
  }